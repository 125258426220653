import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { fNumber } from '../../../../utils/formatNumber';

const PriceWithColor = ({ price, variant, digits = 2 }) => {
  const [color, setColor] = useState(null);
  const prevPriceRef = useRef(price);

  useEffect(() => {
    const prevPrice = prevPriceRef.current;

    if (price > prevPrice) {
      setColor('blue');
    } else if (price < prevPrice) {
      setColor('red');
    } else {
      setColor(null); // Keep the previously updated color
    }

    // Update the previous price
    prevPriceRef.current = price;
  }, [price]);

  return (
    <Typography variant={variant} color={color} alignItems={'center'}>
      {fNumber(price || 0, digits)}
    </Typography>
  );
};

PriceWithColor.propTypes = {
  price: PropTypes.number || PropTypes.string,
  variant: PropTypes.string,
  digits: PropTypes.number,
};

export default PriceWithColor;
