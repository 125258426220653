// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/superadmin';
const ROOTS_DASHBOARD_OPERATOR = '/broker';
const ROOTS_DASHBOARD_INSURER = '/admin';
const ROOTS_DASHBOARD_BANKER = '/client';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile'),
  },

  // OPERATOR BROKER
  operatorRole: {
    root: ROOTS_DASHBOARD_OPERATOR,
    general: {
      app: path(ROOTS_DASHBOARD_OPERATOR, '/app'),
    },
    watchlist: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/watchlist'),
      add: path(ROOTS_DASHBOARD_OPERATOR, '/watchlist/new'),
    },
    clients: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/client'),
      new: path(ROOTS_DASHBOARD_OPERATOR, '/client/new'),
      view: (id) => path(ROOTS_DASHBOARD_OPERATOR, `/client/${id}`),
      edit: (id) => path(ROOTS_DASHBOARD_OPERATOR, `/client/edit/${id}`),
      trade: path(ROOTS_DASHBOARD_OPERATOR, '/clients/trades'),
    },
    instrument: {
      all: path(ROOTS_DASHBOARD_OPERATOR, '/instrument'),
    },
    positions: {
      grouped: {
        root: path(ROOTS_DASHBOARD_OPERATOR, '/positions/grouped'),
        active: path(ROOTS_DASHBOARD_OPERATOR, '/positions/grouped/active'),
        closed: path(ROOTS_DASHBOARD_OPERATOR, '/positions/grouped/closed'),
        view: (type, scriptId) =>
          path(ROOTS_DASHBOARD_OPERATOR, `/positions/grouped/${type === 'OPEN' ? 'active' : 'closed'}/${scriptId}`),
      },
    },
    brokerlist: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/brokerlist'),
    },
    trades: {
      executed: path(ROOTS_DASHBOARD_OPERATOR, '/trades/executed'),
      pending: path(ROOTS_DASHBOARD_OPERATOR, '/trades/pending'),
    },
    log: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/log'),
    },
    lineTrades: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/line-trades'),
    },
    projects: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/project/list'),
      new: path(ROOTS_DASHBOARD_OPERATOR, '/project/new'),
      view: (id) => path(ROOTS_DASHBOARD_OPERATOR, `/project/${id}`),
      edit: (id) => path(ROOTS_DASHBOARD_OPERATOR, `/project/${id}/edit`),
    },
    chat: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/chat'),
      new: path(ROOTS_DASHBOARD_OPERATOR, '/chat/new'),
      view: (name) => path(ROOTS_DASHBOARD_OPERATOR, `/chat/${name}`),
    },
    profile: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/profile'),
    },
    setting: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/settings'),
      // equity: path(ROOTS_DASHBOARD_OPERATOR, '/settings/equity'),
      general: path(ROOTS_DASHBOARD_OPERATOR, '/settings/general'),
      changePassword: path(ROOTS_DASHBOARD_OPERATOR, '/settings/change-password'),
    },
    report: {
      root: path(ROOTS_DASHBOARD_OPERATOR, '/reports'),
      weekly: path(ROOTS_DASHBOARD_OPERATOR, '/reports/weekly'),
      riskyUser: path(ROOTS_DASHBOARD_OPERATOR, '/reports/risky-users'),
    },
  },

  // INSURER ADMIN
  insurerRole: {
    root: ROOTS_DASHBOARD_INSURER,
    general: {
      app: path(ROOTS_DASHBOARD_INSURER, '/app'),
    },
    watchlist: {
      root: path(ROOTS_DASHBOARD_INSURER, '/watchlist'),
      add: path(ROOTS_DASHBOARD_INSURER, '/watchlist/new'),
    },
    tradingView: {
      root: path(ROOTS_DASHBOARD_INSURER, '/tv-watchlist'),
      add: path(ROOTS_DASHBOARD_INSURER, '/tv-watchlist/new'),
    },
    broker: {
      root: path(ROOTS_DASHBOARD_INSURER, '/broker'),
      rootList: path(ROOTS_DASHBOARD_INSURER, '/broker/list'),
      new: path(ROOTS_DASHBOARD_INSURER, '/broker/new'),
      view: (id) => path(ROOTS_DASHBOARD_INSURER, `/broker/${id}`),
      list: (id) => path(ROOTS_DASHBOARD_INSURER, `/broker/list/${id}`),
      listclient: (brokerId, clientId) => path(ROOTS_DASHBOARD_INSURER, `/broker/list/${brokerId}/${clientId}`),
      editBroker: (id) => path(ROOTS_DASHBOARD_INSURER, `/broker/edit/${id}`),
      duplicateBroker: (id) => path(ROOTS_DASHBOARD_INSURER, `/broker/duplicate/${id}`),
      editClient: (id) => path(ROOTS_DASHBOARD_INSURER, `/broker/client/edit/${id}`),
      client: {
        new: path(ROOTS_DASHBOARD_INSURER, '/broker/client/new'),
        edit: (id) => path(ROOTS_DASHBOARD_INSURER, `/broker/client/edit/${id}`),
        view: (id) => path(ROOTS_DASHBOARD_INSURER, `/broker/client/${id}`),
        duplicate: (id) => path(ROOTS_DASHBOARD_INSURER, `/broker/client/duplicate/${id}`),
      },
    },
    log: {
      root: path(ROOTS_DASHBOARD_INSURER, '/log'),
    },
    lineTrades: {
      root: path(ROOTS_DASHBOARD_INSURER, '/line-trades'),
    },
    client: {
      root: path(ROOTS_DASHBOARD_INSURER, '/client'),
      list: path(ROOTS_DASHBOARD_INSURER, '/client/list'),
      edit: (id) => path(ROOTS_DASHBOARD_INSURER, `/client/edit/${id}`),
      view: (id) => path(ROOTS_DASHBOARD_INSURER, `/client/${id}`),
    },
    instrument: {
      all: path(ROOTS_DASHBOARD_INSURER, '/instrument'),
    },
    positions: {
      grouped: {
        root: path(ROOTS_DASHBOARD_INSURER, '/positions/grouped'),
        active: path(ROOTS_DASHBOARD_INSURER, '/positions/grouped/active'),
        closed: path(ROOTS_DASHBOARD_INSURER, '/positions/grouped/closed'),
        view: (type, scriptId) =>
          path(ROOTS_DASHBOARD_INSURER, `/positions/grouped/${type === 'OPEN' ? 'active' : 'closed'}/${scriptId}`),
      },
      userwiseGrouped: {
        active: (id) => path(ROOTS_DASHBOARD_INSURER, `/positions/${id}/grouped/active`),
      },
    },
    brokerlist: {
      root: path(ROOTS_DASHBOARD_INSURER, '/brokerlist'),
    },
    trades: {
      executed: path(ROOTS_DASHBOARD_INSURER, '/trades/executed'),
      pending: path(ROOTS_DASHBOARD_INSURER, '/trades/pending'),
    },
    chat: {
      root: path(ROOTS_DASHBOARD_INSURER, '/chat'),
      new: path(ROOTS_DASHBOARD_INSURER, '/chat/new'),
      view: (name) => path(ROOTS_DASHBOARD_INSURER, `/chat/${name}`),
    },
    profile: {
      root: path(ROOTS_DASHBOARD_INSURER, '/profile'),
    },
    setting: {
      root: path(ROOTS_DASHBOARD_INSURER, '/settings'),
      equity: path(ROOTS_DASHBOARD_INSURER, '/settings/equity'),
      general: path(ROOTS_DASHBOARD_INSURER, '/settings/general'),
      changePassword: path(ROOTS_DASHBOARD_INSURER, '/settings/change-password'),
    },
    report: {
      root: path(ROOTS_DASHBOARD_INSURER, '/reports'),
      weekly: path(ROOTS_DASHBOARD_INSURER, '/reports/weekly'),
      riskyUser: path(ROOTS_DASHBOARD_INSURER, '/reports/risky-users'),
    },
  },

  // BANKER Client

  bankerRole: {
    root: ROOTS_DASHBOARD_BANKER,
    general: {
      app: path(ROOTS_DASHBOARD_BANKER, '/app'),
    },
    loan: {
      root: path(ROOTS_DASHBOARD_BANKER, '/loan/list'),
    },
    instrument: {
      all: path(ROOTS_DASHBOARD_BANKER, '/instrument'),
    },
    chat: {
      root: path(ROOTS_DASHBOARD_BANKER, '/chat'),
      new: path(ROOTS_DASHBOARD_BANKER, '/chat/new'),
      view: (name) => path(ROOTS_DASHBOARD_BANKER, `/chat/${name}`),
    },
    profile: {
      root: path(ROOTS_DASHBOARD_BANKER, '/profile'),
    },
    history: {
      root: path(ROOTS_DASHBOARD_BANKER, '/history'),
    },
    portfolio: {
      root: path(ROOTS_DASHBOARD_BANKER, '/portfolio'),
    },
    trades: {
      root: path(ROOTS_DASHBOARD_BANKER, '/trades'),
    },
    watchlist: {
      root: path(ROOTS_DASHBOARD_BANKER, '/watchlist'),
      add: path(ROOTS_DASHBOARD_BANKER, '/watchlist/new'),
    },
    tradingView: {
      root: path(ROOTS_DASHBOARD_BANKER, '/tv-watchlist'),
      add: path(ROOTS_DASHBOARD_BANKER, '/tv-watchlist/new'),
    },
    tradingViewDemo: {
      root: path(ROOTS_DASHBOARD_BANKER, '/tradingview'),
    },
  },

  // SUPERADMIN
  forwarder: {
    root: path(ROOTS_DASHBOARD, '/forwarder'),
    view: (id) => path(ROOTS_DASHBOARD, `/forwarder/${id}`),
  },
  insurer: {
    root: path(ROOTS_DASHBOARD, '/insurer'),
    new: path(ROOTS_DASHBOARD, '/insurer/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/insurer/${id}`),
  },
  clientApplications: {
    root: path(ROOTS_DASHBOARD, '/client-applications'),
    view: (id) => path(ROOTS_DASHBOARD, `/client-applications/${id}`),
    assign: (id) => path(ROOTS_DASHBOARD, `/client-applications/${id}/assign`),
  },
  report: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    exchangeTiming: path(ROOTS_DASHBOARD, '/reports/exchange-timing'),
    priceconfig: path(ROOTS_DASHBOARD, '/reports/price-config'),
    // weekly: path(ROOTS_DASHBOARD, '/reports/weekly'),
    riskyUser: path(ROOTS_DASHBOARD, '/reports/risky-users'),
  },
  operator: {
    root: path(ROOTS_DASHBOARD, '/admin/list'),
    new: path(ROOTS_DASHBOARD, '/admin/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/admin/list/${id}`),
    list: (id) => path(ROOTS_DASHBOARD, `/admin/list/${id}`),
    editAdmin: (id) => path(ROOTS_DASHBOARD, `/admin/edit/${id}`),
    editClient: (id) => path(ROOTS_DASHBOARD, `/admin/client/edit/${id}`),
    listBroker: (adminId, brokerId) => path(ROOTS_DASHBOARD, `/admin/list/${adminId}/${brokerId}`),
    listclient: (adminId, brokerId, clientId) =>
      path(ROOTS_DASHBOARD, `/admin/list/${adminId}/${brokerId}/${clientId}`),
  },
  instrument: {
    all: path(ROOTS_DASHBOARD, '/instrument'),
  },
  project: {
    root: path(ROOTS_DASHBOARD, '/project'),
    view: (id) => path(ROOTS_DASHBOARD, `/project/${id}`),
  },
  banker: {
    root: path(ROOTS_DASHBOARD, '/banker'),
    new: path(ROOTS_DASHBOARD, '/banker/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/banker/${id}`),
  },
  clientLoans: {
    root: path(ROOTS_DASHBOARD, '/client-loans'),
    view: (id) => path(ROOTS_DASHBOARD, `/client-loans/${id}`),
    assign: (id) => path(ROOTS_DASHBOARD, `/client-loans/${id}/assign`),
  },
  entries: {
    root: path(ROOTS_DASHBOARD, '/entries'),
    view: (id) => path(ROOTS_DASHBOARD, `/entries/${id}`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
