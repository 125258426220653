/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { ArrowRightAltSharp } from '@mui/icons-material';
import modifyScriptName from '../../../../utils/modifyScriptName';
import CloseTradeModal from './CloseTradeModal';
import PriceWithColor from '../watchlist/PriceWithColor';
import { fNumber } from '../../../../utils/formatNumber';
import { fDateTime } from '../../../../utils/formatTime';

const TradeCard = ({ trade, priceChangeConfig, closeFromTrade = false }) => {
  const { script, side, quantity, buy, sell, type, margin, boughtBy, soldBy, boughtAt, soldAt } = trade;

  const [openModal, setOpenModal] = useState(false);
  let multiplicationFactor = 1;
  const currentPrice = script?.depth ? script.depth[side === 'BUY' ? 'buy' : 'sell'][0].price : 0;
  const currentSegement = trade?.script?.exchange?.name;
  if (priceChangeConfig && ['MCX', 'COMEX', 'WINDEX', 'NSEIX'].includes(currentSegement)) {
    multiplicationFactor =
      priceChangeConfig[currentSegement][trade.script.name] || priceChangeConfig[currentSegement].default || 1;
  }
  console.log('Cure nt segme', trade.script, multiplicationFactor);

  // Calculate active profit/loss
  const activeProfitLoss =
    side === 'BUY'
      ? (currentPrice - buy) * quantity * multiplicationFactor
      : (sell - currentPrice) * quantity * multiplicationFactor;

  const openModalHandler = () => setOpenModal(true);
  const closeModalHandler = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: '0.5px solid grey',
          width: '100%',
          pointerEvents: openModal ? 'none' : 'auto',
          cursor: 'pointer',
          ':hover': { backgroundColor: 'action.hover' },
        }}
        onClick={closeFromTrade ? openModalHandler : undefined}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {/* First column */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                alignItems: 'center',
                pt: 1,
                justifyContent: 'flex-start',
              }}
            >
              <Typography variant="h5">{modifyScriptName(script.tradingsymbol)},</Typography>
              <Typography variant="h6" textTransform={'capitalize'} color={`${side === 'BUY' ? 'blue' : 'red'}`}>
                {side.toLowerCase()} {quantity}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, pt: 0.5 }}>
              <Typography variant="subtitle2">{side === 'BUY' ? fNumber(buy) : fNumber(sell)}</Typography>
              {type === 'OPEN' && <ArrowRightAltSharp />}
              {type === 'OPEN' && <Typography variant="subtitle2">{fNumber(currentPrice?.toFixed(2))}</Typography>}
            </Box>
            <Typography variant="subtitle3">Margin Used: {fNumber(Number.parseInt(margin, 10))}</Typography>
            {closeFromTrade && (
              <Typography variant="subtitle3">
                {trade.side === 'BUY'
                  ? `Bought at ${fDateTime(boughtAt)} (${boughtBy})`
                  : `Sold at ${fDateTime(soldAt)} (${soldBy})`}
              </Typography>
            )}
          </Box>
          {/* Second column */}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            {type === 'OPEN' ? (
              <Typography variant="h3" sx={{ color: `${+activeProfitLoss < 0 ? 'red' : 'blue'}` }}>
                {fNumber(activeProfitLoss.toFixed(2))}
              </Typography>
            ) : (
              <PriceWithColor
                price={script?.depth ? script?.depth[side === 'BUY' ? 'sell' : 'buy'][0].price : 0}
                digits={script?.exchange?.name === 'COMEX' && script?.name === 'SILVER' ? 4 : 2}
                variant="h3"
              />
            )}
          </Box>
        </Box>
      </Box>
      <CloseTradeModal
        isOpen={openModal}
        onClose={closeModalHandler}
        trade={trade}
        updatedQuantity={quantity}
        closeFromTrade={closeFromTrade}
      />
    </>
  );
};

export default TradeCard;
